import React from 'react';
import ContentPage from './ContentPage';
import { pageContent } from '../utils/contentUtils';

const TermsOfUsePage: React.FC = () => {
  const { title, description, fileName } = pageContent.termsOfUse;
  
  return (
    <ContentPage
      title={title}
      description={description}
      fileName={fileName}
      showCallToAction={false}
    />
  );
};

export default TermsOfUsePage;
