import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import ContentSection from '../components/ContentSection';
import CallToAction from '../components/CallToAction';
import ImageWithOverlay from '../components/ImageWithOverlay';
import ProcessStep from '../components/ProcessStep';
import { pageContent } from '../utils/contentUtils';

const StructuredSolutionsPage: React.FC = () => {
  const { title, description, fileName } = pageContent.structuredSolutions;
  
  const structuredProducts = [
    {
      title: "Capital Protection Structures",
      description: "Products designed to protect your principal investment while providing potential for returns",
      image: "/images/structured/protection.jpg",
      items: [
        "Principal Protected Notes: Guarantee the return of principal at maturity with participation in market upside",
        "Barrier Protection Products: Offer conditional downside protection as long as certain market levels are not breached",
        "Buffered Notes: Provide protection against initial market declines up to a predetermined buffer level"
      ]
    },
    {
      title: "Yield Enhancement Structures",
      description: "Products that aim to generate enhanced income in various market conditions",
      image: "/images/structured/yield.jpg",
      items: [
        "Reverse Convertibles: Offer above-market coupon payments with potential equity conversion at maturity",
        "Autocallable Notes: Provide attractive coupon payments with early redemption features",
        "Range Accrual Notes: Generate income based on the performance of an underlying asset within a specified range"
      ]
    },
    {
      title: "Participation Structures",
      description: "Products that provide leveraged or modified exposure to various markets",
      image: "/images/structured/participation.jpg",
      items: [
        "Participation Notes: Offer enhanced participation in the upside of an underlying asset",
        "Leveraged Index Products: Provide amplified exposure to market movements",
        "Outperformance Notes: Generate returns based on the relative performance of different assets or indices"
      ]
    },
    {
      title: "Thematic Investment Structures",
      description: "Products that provide access to specific investment themes or strategies",
      image: "/images/structured/thematic.jpg",
      items: [
        "ESG-Linked Structures: Exposure to environmental, social, and governance themes",
        "Sector-Specific Products: Targeted exposure to specific industry sectors",
        "Multi-Asset Structures: Diversified exposure across various asset classes"
      ]
    }
  ];

  const approachSteps = [
    {
      number: 1,
      title: "Needs Assessment",
      description: "We begin by understanding your investment objectives, risk tolerance, market outlook, and time horizon to identify the most appropriate structured solutions."
    },
    {
      number: 2,
      title: "Product Design",
      description: "Based on our assessment, we design or select structured products that align with your investment goals, considering factors such as payoff structure, underlying assets, and maturity."
    },
    {
      number: 3,
      title: "Implementation",
      description: "We assist you throughout the investment process, ensuring you fully understand the features, risks, and potential outcomes of the structured products in your portfolio."
    },
    {
      number: 4,
      title: "Ongoing Monitoring",
      description: "We continuously monitor the performance of your structured investments and provide regular updates on their progress toward your investment objectives."
    }
  ];

  const benefits = [
    {
      title: "Customization",
      description: "Tailored investment solutions designed to meet your specific objectives",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Risk Management",
      description: "Ability to define and limit downside risk while maintaining upside potential",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Diversification",
      description: "Access to a wide range of markets, sectors, and investment strategies",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
      )
    },
    {
      title: "Yield Enhancement",
      description: "Potential for enhanced returns in various market conditions",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Capital Protection",
      description: "Options for principal protection or defined risk parameters",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      )
    }
  ];

  const whyChooseUs = [
    {
      title: "Expertise",
      description: "Specialized knowledge of structured products and derivatives",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Customization",
      description: "Ability to design bespoke solutions tailored to your needs",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Transparency",
      description: "Clear explanation of product features, risks, and potential outcomes",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Global Access",
      description: "Relationships with leading issuers of structured products worldwide",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Ongoing Support",
      description: "Regular reviews and adjustments to ensure your structured investments remain aligned with your goals",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
        </svg>
      )
    }
  ];
  
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      
      {/* Hero Section */}
      <div className="relative text-white">
        <div className="absolute inset-0 z-0">
          <img
            src="/images/structured/hero.jpg"
            alt="Structured Solutions"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-primary bg-opacity-75"></div>
        </div>
        
        <div className="container py-24 relative z-10">
          <div className="max-w-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-6">
              {title}
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Innovative investment products designed to meet specific objectives with customized risk-return profiles
            </p>
          </div>
        </div>
      </div>
      
      {/* Introduction Section */}
      <ContentSection>
        <div className="mb-12">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-6">Innovative Structured Investment Products</h2>
          <p className="text-lg text-gray-600">
            At Mayfair Capital Markets, we offer sophisticated structured investment solutions designed to meet specific investment objectives. Our structured products combine different financial instruments to create customized risk-return profiles that can enhance portfolio performance, provide downside protection, or generate income in various market conditions.
          </p>
        </div>
        
        <div className="mb-16">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-6">What Are Structured Solutions?</h2>
          <p className="text-lg text-gray-600 mb-6">
            Structured solutions are customized investment products that combine traditional securities (such as bonds or equities) with derivatives to create unique payoff profiles. These products are designed to achieve specific investment objectives.
          </p>
          
          <div className="bg-gray-50 p-6 rounded-lg">
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span>Capital protection with participation in market upside</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span>Enhanced yield in flat or moderately rising markets</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span>Downside protection with capped upside potential</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span>Exposure to specific market sectors or themes</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span>Diversification across multiple asset classes</span>
              </li>
            </ul>
          </div>
        </div>
      </ContentSection>
      
      {/* Structured Solutions Offerings */}
      <div className="bg-gray-50 py-16">
        <div className="container">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-12 text-center">Our Structured Solutions Offerings</h2>
          
          {structuredProducts.map((product, index) => (
            <div key={index} className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center ${index < structuredProducts.length - 1 ? 'mb-16' : ''}`}>
              <div className={index % 2 === 1 ? "order-1 lg:order-2" : ""}>
                <h3 className="text-2xl font-serif font-bold text-gray-800 mb-4">{product.title}</h3>
                <p className="text-gray-600 mb-6">{product.description}</p>
                <ul className="space-y-3">
                  {product.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0 mt-0.5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={index % 2 === 1 ? "order-2 lg:order-1" : ""}>
                <img
                  src={product.image}
                  alt={product.title}
                  className="rounded-lg shadow-lg w-full h-auto object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Our Approach */}
      <ContentSection>
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-12 text-center">Our Approach to Structured Solutions</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {approachSteps.map((step, index) => (
            <ProcessStep
              key={index}
              number={step.number}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
        
        {/* Benefits */}
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Benefits of Our Structured Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
        
        {/* Why Choose Us */}
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Why Choose Our Structured Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {whyChooseUs.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </ContentSection>
      
      <CallToAction />
    </Layout>
  );
};

export default StructuredSolutionsPage;
