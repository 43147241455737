import React, { useEffect, useState } from 'react';
import { renderMarkdown } from '../utils/markdownUtils';
import { fetchMarkdownContent } from '../utils/contentUtils';

interface MarkdownContentProps {
  fileName: string;
}

const MarkdownContent: React.FC<MarkdownContentProps> = ({ fileName }) => {
  const [content, setContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadContent = async () => {
      try {
        setIsLoading(true);
        const markdownContent = await fetchMarkdownContent(fileName);
        setContent(markdownContent);
        setError(null);
      } catch (err) {
        console.error(`Error loading markdown content for ${fileName}:`, err);
        setError('Failed to load content. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadContent();
  }, [fileName]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-12 text-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return <div className="prose prose-lg max-w-none">{renderMarkdown(content)}</div>;
};

export default MarkdownContent;
