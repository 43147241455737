import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContentSection from '../components/ContentSection';
import CallToAction from '../components/CallToAction';
import TeamMember from '../components/TeamMember';
import ValueCard from '../components/ValueCard';
import TimelineItem from '../components/TimelineItem';
import StatItem from '../components/StatItem';
import { pageContent } from '../utils/contentUtils';

const AboutPage: React.FC = () => {
  const { title, description } = pageContent.about;
  
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
          integrity="sha512-9usAa10IRO0HhonpyAIVpjrylPvoDwiPUiKdWk5t3PyolY1cOd4DSE0Ga+ri4AuTroPR5aQvXU9xC6qOPnzFeg=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
      
      {/* Hero Section */}
      <div className="relative">
        <div className="h-96 w-full overflow-hidden">
          <img 
            src="/images/about/hero.jpg" 
            alt="Mayfair Capital Markets Building" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center text-white px-4">
              <h1 className="text-4xl md:text-5xl font-bold mb-4">About Mayfair Capital Markets</h1>
              <p className="text-xl max-w-3xl mx-auto">
                A distinguished financial services firm with a global presence
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Company Overview */}
      <ContentSection>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Company</h2>
            <p className="text-gray-600 mb-4">
              Mayfair Capital Markets is a distinguished financial services firm headquartered in London with a global presence. 
              Established with the vision of providing exceptional financial services, we have grown to become a trusted partner 
              for investors seeking sophisticated investment solutions.
            </p>
            <p className="text-gray-600 mb-4">
              Our mission is to empower our clients to achieve their financial objectives through personalized strategies, 
              innovative solutions, and unwavering commitment to excellence. We strive to build long-term relationships 
              based on trust, transparency, and exceptional service.
            </p>
            <div className="mt-8">
              <a 
                href="/contact-us" 
                className="inline-block bg-primary text-white font-medium py-3 px-6 rounded-md hover:bg-primary-dark transition-colors"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div>
            <img 
              src="/images/about/office.jpg" 
              alt="Mayfair Capital Markets Office" 
              className="rounded-lg shadow-xl w-full h-auto"
            />
          </div>
        </div>
      </ContentSection>


      {/* Our Values */}
      <ContentSection>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Values</h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Our core values guide everything we do and define our approach to serving our clients.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <ValueCard
            title="Integrity"
            description="We uphold the highest ethical standards in all our dealings, ensuring transparency and honesty in every interaction."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            }
          />
          <ValueCard
            title="Excellence"
            description="We are committed to delivering superior results and service, constantly striving to exceed expectations."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            }
          />
          <ValueCard
            title="Innovation"
            description="We continuously seek innovative approaches to financial challenges, embracing new technologies and methodologies."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
              </svg>
            }
          />
          <ValueCard
            title="Client Focus"
            description="We place our clients' interests at the center of everything we do, tailoring our solutions to their unique needs."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
              </svg>
            }
          />
          <ValueCard
            title="Global Perspective"
            description="We leverage our international expertise to provide comprehensive solutions that address global market dynamics."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
              </svg>
            }
          />
          <ValueCard
            title="Collaboration"
            description="We believe in the power of teamwork and partnership, working together to achieve common goals."
            icon={
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v1h8v-1zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-1a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v1h-3zM4.75 12.094A5.973 5.973 0 004 15v1H1v-1a3 3 0 013.75-2.906z" />
              </svg>
            }
          />
        </div>
      </ContentSection>

      {/* Our Approach */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <img 
                src="/images/about/handshake.jpg" 
                alt="Business Meeting" 
                className="rounded-lg shadow-xl w-full h-auto"
              />
            </div>
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Approach</h2>
              <p className="text-gray-600 mb-4">
                At Mayfair Capital Markets, we follow a systematic approach to understanding our clients' financial goals, 
                risk tolerance, and investment preferences. This enables us to create customized strategies that align 
                with their objectives and deliver sustainable results.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary text-white flex items-center justify-center mr-3 mt-1">
                    <span className="text-sm font-bold">1</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-900">Comprehensive Assessment</h3>
                    <p className="text-gray-600">We begin by understanding your financial situation, goals, and risk tolerance.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary text-white flex items-center justify-center mr-3 mt-1">
                    <span className="text-sm font-bold">2</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-900">Strategic Planning</h3>
                    <p className="text-gray-600">We develop a customized strategy aligned with your objectives and market conditions.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary text-white flex items-center justify-center mr-3 mt-1">
                    <span className="text-sm font-bold">3</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-900">Implementation</h3>
                    <p className="text-gray-600">We execute the strategy with precision and attention to detail.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary text-white flex items-center justify-center mr-3 mt-1">
                    <span className="text-sm font-bold">4</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-900">Ongoing Management</h3>
                    <p className="text-gray-600">We continuously monitor and adjust your portfolio to ensure optimal performance.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/* Our History */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our History</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Since our inception, we have been dedicated to providing exceptional financial services to our clients.
            </p>
          </div>
          <div className="space-y-12">
            <TimelineItem 
              year="2010" 
              title="Foundation" 
              description="Mayfair Capital Markets was founded in London with a vision to provide sophisticated investment solutions."
              isLeft={true}
            />
            <TimelineItem 
              year="2013" 
              title="International Expansion" 
              description="We expanded our operations to key financial centers in Europe and Asia, establishing a global presence."
              isLeft={false}
            />
            <TimelineItem 
              year="2016" 
              title="Launch of Wealth Management" 
              description="We introduced comprehensive wealth management services to address the holistic financial needs of our clients."
              isLeft={true}
            />
            <TimelineItem 
              year="2019" 
              title="Digital Transformation" 
              description="We embraced digital technologies to enhance client experience and streamline our operations."
              isLeft={false}
            />
            <TimelineItem 
              year="2022" 
              title="Sustainable Investing Initiative" 
              description="We launched our sustainable investing platform, integrating ESG factors into our investment process."
              isLeft={true}
            />
          </div>
        </div>
      </div>

      {/* Global Presence */}
      <ContentSection>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Global Presence</h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Today, we are proud to serve clients across the globe, helping them navigate 
            the complexities of the financial world and achieve their goals.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-bold text-gray-900 mb-3">London</h3>
            <p className="text-gray-600 mb-2">Headquarters</p>
            <p className="text-gray-600">2 Leman Street, London, United Kingdom, E1W 9US</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-bold text-gray-900 mb-3">Singapore</h3>
            <p className="text-gray-600 mb-2">Asia-Pacific Hub</p>
            <p className="text-gray-600">9 Battery Road, #28-01, 049910, Singapore</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-bold text-gray-900 mb-3">Malaysia</h3>
            <p className="text-gray-600 mb-2">Southeast Asia Hub</p>
            <p className="text-gray-600">Duplex Penthouse, Unit A-33A-6, Level 33A, Tower A, UOA Bangsar Tower, No.5, Bangsar Utama 1 Road, 59000 Kuala Lumpur, Malaysia</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-bold text-gray-900 mb-3">Hong Kong</h3>
            <p className="text-gray-600 mb-2">Greater China Hub</p>
            <p className="text-gray-600">Level 20, One International Finance Centre, No. 1 Harbour View Street, Central, Hong Kong</p>
          </div>
        </div>
      </ContentSection>

      {/* Final CTA */}
      <div className="bg-primary text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Work With Us?</h2>
          <p className="text-xl max-w-3xl mx-auto mb-8">
            Contact us today to learn more about our company and how we can assist you with your financial needs.
          </p>
          <a 
            href="/contact-us" 
            className="inline-block bg-white text-primary font-medium py-3 px-8 rounded-md hover:bg-gray-100 transition-colors"
          >
            Get in Touch
          </a>
        </div>
      </div>

      <CallToAction />
    </Layout>
  );
};

export default AboutPage;
