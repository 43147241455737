/**
 * Fetches markdown content from the website_content directory
 * @param fileName The name of the markdown file to fetch (without the .md extension)
 * @returns A promise that resolves to the markdown content
 */
export const fetchMarkdownContent = async (fileName: string): Promise<string> => {
  try {
    const response = await fetch(`/website_content/${fileName}.md`);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${fileName}.md`);
    }
    return await response.text();
  } catch (error) {
    console.error(`Error fetching ${fileName}.md:`, error);
    return '';
  }
};

/**
 * Content data for each page
 */
export const pageContent = {
  home: {
    title: 'Home',
    description: 'Mayfair Capital Markets - Premier Financial Services Company',
    fileName: 'home',
  },
  about: {
    title: 'About Us',
    description: 'Learn about Mayfair Capital Markets, our mission, values, and team',
    fileName: 'about',
  },
  investmentAdvisory: {
    title: 'Investment Advisory',
    description: 'Expert investment guidance and personalized investment strategies',
    fileName: 'investment-advisory',
  },
  wealthManagement: {
    title: 'Wealth Management',
    description: 'Comprehensive wealth management solutions for high-net-worth individuals',
    fileName: 'wealth-management',
  },
  insuranceSolutions: {
    title: 'Insurance Solutions',
    description: 'Diverse insurance products to protect your assets and secure your future',
    fileName: 'insurance-solutions',
  },
  structuredSolutions: {
    title: 'Structured Solutions',
    description: 'Custom-designed financial products to meet specific investment needs',
    fileName: 'structured-solutions',
  },
  fundsSolutions: {
    title: 'Funds Solutions',
    description: 'Access to a wide range of investment funds with professional management',
    fileName: 'funds-solutions',
  },
  contactUs: {
    title: 'Contact Us',
    description: 'Get in touch with Mayfair Capital Markets',
    fileName: 'contact-us',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    description: 'Mayfair Capital Markets Privacy Policy',
    fileName: 'privacy-policy',
  },
  cookiesPolicy: {
    title: 'Cookies Policy',
    description: 'Mayfair Capital Markets Cookies Policy',
    fileName: 'cookies-policy',
  },
  termsOfUse: {
    title: 'Terms of Use',
    description: 'Mayfair Capital Markets Terms of Use',
    fileName: 'terms-of-use',
  },
};
