import React from 'react';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="bg-primary text-white py-16">
      <div className="container">
        <h1 className="text-4xl md:text-5xl font-serif font-bold mb-4">{title}</h1>
        {subtitle && <p className="text-xl md:text-2xl max-w-3xl">{subtitle}</p>}
      </div>
    </div>
  );
};

export default PageHeader;
