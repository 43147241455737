import React from 'react';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
  return (
    <div className="relative text-white">
      {/* Hero Background Image */}
      <div className="absolute inset-0 z-0">
        <img 
          src="/images/home/hero.jpg" 
          alt="Financial district skyline" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-primary bg-opacity-75"></div>
      </div>
      
      <div className="container py-24 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-6">
              Premier Financial Services
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Comprehensive investment solutions to help you achieve your financial goals.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/contact-us" className="btn btn-secondary">
                Contact Us
              </Link>
              <Link to="/about" className="btn bg-white text-primary hover:bg-gray-100">
                Learn More
              </Link>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="bg-white/10 p-8 rounded-lg backdrop-blur-sm">
              <h2 className="text-2xl font-serif font-bold mb-6">Our Services</h2>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-secondary mr-2 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div>
                    <h3 className="font-bold">Investment Advisory</h3>
                    <p className="text-white/80">Expert guidance on investment opportunities</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-secondary mr-2 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div>
                    <h3 className="font-bold">Wealth Management</h3>
                    <p className="text-white/80">Comprehensive solutions for high-net-worth individuals</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-secondary mr-2 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div>
                    <h3 className="font-bold">Insurance Solutions</h3>
                    <p className="text-white/80">Diverse products to protect your assets</p>
                  </div>
                </li>
              </ul>
              <Link
                to="/investment-advisory"
                className="mt-6 inline-block text-secondary hover:text-white font-medium"
              >
                View All Services →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
