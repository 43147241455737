import React from 'react';

interface BenefitItemProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ title, description, icon }) => {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0 mr-4 text-secondary text-2xl">
        {icon}
      </div>
      <div>
        <h3 className="text-lg font-serif font-bold text-gray-800 mb-1">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default BenefitItem;
