import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  HomePage,
  AboutPage,
  InvestmentAdvisoryPage,
  WealthManagementPage,
  InsuranceSolutionsPage,
  StructuredSolutionsPage,
  FundsSolutionsPage,
  ContactPage,
  PrivacyPolicyPage,
  CookiesPolicyPage,
  TermsOfUsePage,
} from './pages';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/investment-advisory" element={<InvestmentAdvisoryPage />} />
        <Route path="/wealth-management" element={<WealthManagementPage />} />
        <Route path="/insurance-solutions" element={<InsuranceSolutionsPage />} />
        <Route path="/structured-solutions" element={<StructuredSolutionsPage />} />
        <Route path="/funds-solutions" element={<FundsSolutionsPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
      </Routes>
    </Router>
  );
};

export default App;
