import React, { ReactNode } from 'react';

interface ContentSectionProps {
  children: ReactNode;
  className?: string;
}

const ContentSection: React.FC<ContentSectionProps> = ({ children, className = '' }) => {
  return (
    <section className={`py-12 ${className}`}>
      <div className="container">
        <div className="prose prose-lg max-w-none">
          {children}
        </div>
      </div>
    </section>
  );
};

export default ContentSection;
