import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import ContentSection from '../components/ContentSection';
import CallToAction from '../components/CallToAction';
import ImageWithOverlay from '../components/ImageWithOverlay';
import ProcessStep from '../components/ProcessStep';
import { pageContent } from '../utils/contentUtils';

const FundsSolutionsPage: React.FC = () => {
  const { title, description, fileName } = pageContent.fundsSolutions;
  
  const fundCategories = [
    {
      title: "Equity Funds",
      description: "Our equity fund offerings provide exposure to global stock markets",
      image: "/images/funds/equity.jpg",
      items: [
        "Global Equity Funds: Broad exposure to developed and emerging markets worldwide",
        "Regional Equity Funds: Focused investments in specific regions such as Europe, Asia, or North America",
        "Country-Specific Funds: Targeted exposure to individual country markets",
        "Sector Funds: Investments in specific industry sectors such as technology, healthcare, or finance",
        "Thematic Funds: Exposure to long-term investment themes such as innovation, sustainability, or demographics"
      ]
    },
    {
      title: "Fixed Income Funds",
      description: "Our fixed income fund offerings provide income and stability",
      image: "/images/structured/yield.jpg", // Using an image from structured solutions
      items: [
        "Government Bond Funds: Investments in sovereign debt from various countries",
        "Corporate Bond Funds: Exposure to corporate debt across different credit ratings",
        "High-Yield Bond Funds: Investments in higher-yielding, lower-rated corporate bonds",
        "Emerging Market Debt Funds: Access to government and corporate bonds from emerging economies",
        "Municipal Bond Funds: Investments in tax-advantaged municipal securities"
      ]
    },
    {
      title: "Alternative Investment Funds",
      description: "Our alternative investment fund offerings provide diversification beyond traditional assets",
      image: "/images/funds/alternative.jpg",
      items: [
        "Hedge Funds: Access to various hedge fund strategies with potential for absolute returns",
        "Private Equity Funds: Investments in private companies with growth potential",
        "Real Estate Funds: Exposure to commercial, residential, and specialized real estate assets",
        "Infrastructure Funds: Investments in essential infrastructure projects worldwide",
        "Commodity Funds: Access to natural resources and commodities markets"
      ]
    },
    {
      title: "Multi-Asset Funds",
      description: "Our multi-asset fund offerings provide diversified exposure across multiple asset classes",
      image: "/images/funds/multi-asset.jpg",
      items: [
        "Balanced Funds: Diversified portfolios of stocks and bonds",
        "Target-Date Funds: Asset allocation that adjusts based on a specific time horizon",
        "Risk-Based Funds: Portfolios designed for specific risk tolerance levels",
        "Income-Focused Funds: Multi-asset strategies aimed at generating regular income",
        "Absolute Return Funds: Strategies designed to deliver positive returns in various market conditions"
      ]
    },
    {
      title: "Specialized Funds",
      description: "Our specialized fund offerings address specific investment needs",
      image: "/images/structured/thematic.jpg", // Using an image from structured solutions
      items: [
        "ESG/Sustainable Funds: Investments aligned with environmental, social, and governance criteria",
        "Income-Generating Funds: Focused on delivering regular income streams",
        "Capital Preservation Funds: Designed to protect principal while providing modest returns",
        "Currency Funds: Strategies focused on foreign exchange markets",
        "Volatility Funds: Investments designed to benefit from market volatility"
      ]
    }
  ];

  const approachSteps = [
    {
      number: 1,
      title: "Needs Assessment",
      description: "We begin by understanding your investment objectives, risk tolerance, time horizon, and liquidity needs to identify the most appropriate fund solutions."
    },
    {
      number: 2,
      title: "Fund Selection",
      description: "Based on our assessment, we recommend funds that align with your investment goals, considering factors such as performance history, management expertise, risk profile, and fee structure."
    },
    {
      number: 3,
      title: "Portfolio Construction",
      description: "We help you construct a diversified portfolio of funds that provides exposure to various asset classes, regions, and investment strategies to optimize risk-adjusted returns."
    },
    {
      number: 4,
      title: "Ongoing Monitoring",
      description: "We continuously monitor the performance of your fund investments and provide regular updates on their progress toward your investment objectives."
    }
  ];

  const benefits = [
    {
      title: "Professional Management",
      description: "Access to experienced fund managers with specialized expertise",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v1h8v-1zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-1a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v1h-3zM4.75 12.094A5.973 5.973 0 004 15v1H1v-1a3 3 0 013.75-2.906z" />
        </svg>
      )
    },
    {
      title: "Diversification",
      description: "Spread of risk across multiple securities, sectors, and regions",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
      )
    },
    {
      title: "Accessibility",
      description: "Lower investment minimums compared to direct investments in some asset classes",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Liquidity",
      description: "Ability to buy and sell fund shares based on your needs",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Transparency",
      description: "Regular reporting on fund holdings and performance",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
      )
    }
  ];

  const whyChooseUs = [
    {
      title: "Open Architecture",
      description: "Access to funds from leading asset managers worldwide",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Due Diligence",
      description: "Rigorous selection process to identify high-quality funds",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Customization",
      description: "Tailored fund portfolios based on your unique needs",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Ongoing Research",
      description: "Continuous evaluation of fund performance and market conditions",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: "Comprehensive Support",
      description: "Regular reviews and adjustments to ensure your fund investments remain aligned with your goals",
      icon: (
        <svg className="h-6 w-6 text-primary" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" />
        </svg>
      )
    }
  ];
  
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      
      {/* Hero Section */}
      <div className="relative text-white">
        <div className="absolute inset-0 z-0">
          <img
            src="/images/funds/hero.jpg"
            alt="Funds Solutions"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-primary bg-opacity-75"></div>
        </div>
        
        <div className="container py-24 relative z-10">
          <div className="max-w-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-6">
              {title}
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Access to a diverse range of investment funds managed by leading asset managers worldwide
            </p>
          </div>
        </div>
      </div>
      
      {/* Introduction Section */}
      <ContentSection>
        <div className="mb-16">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-6">Comprehensive Fund Investment Solutions</h2>
          <p className="text-lg text-gray-600">
            At Mayfair Capital Markets, we offer access to a diverse range of investment funds managed by leading asset managers worldwide. Our funds solutions provide you with professional management, diversification, and access to various asset classes and investment strategies to help you achieve your financial goals.
          </p>
        </div>
      </ContentSection>
      
      {/* Fund Categories */}
      <div className="bg-gray-50 py-16">
        <div className="container">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-12 text-center">Our Funds Offerings</h2>
          
          {fundCategories.map((category, index) => (
            <div key={index} className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center ${index < fundCategories.length - 1 ? 'mb-16' : ''}`}>
              <div className={index % 2 === 1 ? "order-1 lg:order-2" : ""}>
                <h3 className="text-2xl font-serif font-bold text-gray-800 mb-4">{category.title}</h3>
                <p className="text-gray-600 mb-6">{category.description}</p>
                <ul className="space-y-3">
                  {category.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <svg className="h-6 w-6 text-primary mr-2 flex-shrink-0 mt-0.5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={index % 2 === 1 ? "order-2 lg:order-1" : ""}>
                <img
                  src={category.image}
                  alt={category.title}
                  className="rounded-lg shadow-lg w-full h-auto object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Our Approach */}
      <ContentSection>
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-12 text-center">Our Approach to Funds Solutions</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {approachSteps.map((step, index) => (
            <ProcessStep
              key={index}
              number={step.number}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
        
        {/* Benefits */}
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Benefits of Our Funds Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
        
        {/* Why Choose Us */}
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Why Choose Our Funds Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {whyChooseUs.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </ContentSection>
      
      <CallToAction />
    </Layout>
  );
};

export default FundsSolutionsPage;
