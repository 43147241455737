import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const servicesRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  // Close the services dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target as Node)) {
        setIsServicesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-white shadow-md">
      <div className="container py-4">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <Logo />
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex space-x-8">
            <Link to="/" className="text-gray-800 hover:text-primary font-medium">
              Home
            </Link>
            <Link to="/about" className="text-gray-800 hover:text-primary font-medium">
              About
            </Link>
            <div className="relative" ref={servicesRef}>
              <button 
                className="text-gray-800 hover:text-primary font-medium flex items-center"
                onClick={toggleServices}
                onMouseEnter={() => setIsServicesOpen(true)}
              >
                Services
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 ml-1" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div 
                className={`absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-50 ${isServicesOpen ? 'block' : 'hidden'}`}
                onMouseLeave={() => setIsServicesOpen(false)}
              >
                <Link to="/investment-advisory" className="block px-4 py-2 text-gray-800 hover:bg-light-gray">
                  Investment Advisory
                </Link>
                <Link to="/wealth-management" className="block px-4 py-2 text-gray-800 hover:bg-light-gray">
                  Wealth Management
                </Link>
                <Link to="/insurance-solutions" className="block px-4 py-2 text-gray-800 hover:bg-light-gray">
                  Insurance Solutions
                </Link>
                <Link to="/structured-solutions" className="block px-4 py-2 text-gray-800 hover:bg-light-gray">
                  Structured Solutions
                </Link>
                <Link to="/funds-solutions" className="block px-4 py-2 text-gray-800 hover:bg-light-gray">
                  Funds Solutions
                </Link>
              </div>
            </div>
            <Link to="/contact-us" className="text-gray-800 hover:text-primary font-medium">
              Contact Us
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-gray-800 focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <nav className="md:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-4">
              <Link
                to="/"
                className="text-gray-800 hover:text-primary font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-gray-800 hover:text-primary font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
              <div className="space-y-2">
                <div className="text-gray-800 font-medium">Services</div>
                <div className="pl-4 space-y-2">
                  <Link
                    to="/investment-advisory"
                    className="block text-gray-700 hover:text-primary"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Investment Advisory
                  </Link>
                  <Link
                    to="/wealth-management"
                    className="block text-gray-700 hover:text-primary"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Wealth Management
                  </Link>
                  <Link
                    to="/insurance-solutions"
                    className="block text-gray-700 hover:text-primary"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Insurance Solutions
                  </Link>
                  <Link
                    to="/structured-solutions"
                    className="block text-gray-700 hover:text-primary"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Structured Solutions
                  </Link>
                  <Link
                    to="/funds-solutions"
                    className="block text-gray-700 hover:text-primary"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Funds Solutions
                  </Link>
                </div>
              </div>
              <Link
                to="/contact-us"
                className="text-gray-800 hover:text-primary font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact Us
              </Link>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
