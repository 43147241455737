import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContentSection from '../components/ContentSection';
import MarkdownContent from '../components/MarkdownContent';
import CallToAction from '../components/CallToAction';
import ProcessStep from '../components/ProcessStep';
import ImageWithOverlay from '../components/ImageWithOverlay';
import { pageContent } from '../utils/contentUtils';

const InvestmentAdvisoryPage: React.FC = () => {
  const { title, description, fileName } = pageContent.investmentAdvisory;
  
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      
      {/* Hero Section */}
      <div className="relative text-white">
        <div className="absolute inset-0 z-0">
          <img
            src="/images/investment/hero.jpg"
            alt="Investment Advisory"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-primary bg-opacity-75"></div>
        </div>
        
        <div className="container py-24 relative z-10">
          <div className="max-w-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-6">
              Investment Advisory Services
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Expert guidance and personalized investment strategies to help you achieve your financial goals.
            </p>
          </div>
        </div>
      </div>
      
      {/* Introduction Section */}
      <ContentSection>
        <div className="mb-12">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-6">Expert Investment Guidance</h2>
          <p className="text-lg text-gray-600">
            At Mayfair Capital Markets, our Investment Advisory services provide sophisticated investors with expert guidance and personalized investment strategies. We understand that each client has unique financial goals, risk tolerance, and investment preferences, which is why we take a tailored approach to investment advisory.
          </p>
        </div>
      </ContentSection>
      
      {/* Process Section */}
      <div className="bg-gray-50 py-16">
        <div className="container">
          <h2 className="text-3xl font-serif font-bold text-gray-800 mb-12 text-center">Our Investment Advisory Process</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-12">
            <div>
              <ProcessStep
                number={1}
                title="Comprehensive Assessment"
                description="We begin by conducting a thorough assessment of your financial situation, investment objectives, risk tolerance, and time horizon. This helps us understand your unique needs and preferences."
              />
            </div>
            <div>
              <img
                src="/images/investment/assessment.jpg"
                alt="Comprehensive Assessment"
                className="rounded-lg shadow-lg w-full h-64 object-cover"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-12">
            <div className="order-2 md:order-1">
              <img
                src="/images/investment/planning.jpg"
                alt="Strategic Planning"
                className="rounded-lg shadow-lg w-full h-64 object-cover"
              />
            </div>
            <div className="order-1 md:order-2">
              <ProcessStep
                number={2}
                title="Strategic Planning"
                description="Based on our assessment, we develop a customized investment strategy that aligns with your goals. Our approach considers various factors, including market conditions, economic trends, and investment opportunities."
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-12">
            <div>
              <ProcessStep
                number={3}
                title="Portfolio Construction"
                description="We construct a diversified portfolio using a wide range of investment instruments, including equities, fixed income, alternative investments, and structured products. Our goal is to optimize returns while managing risk."
              />
            </div>
            <div>
              <img
                src="/images/investment/portfolio.jpg"
                alt="Portfolio Construction"
                className="rounded-lg shadow-lg w-full h-64 object-cover"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-12">
            <div className="order-2 md:order-1">
              <img
                src="/images/investment/monitoring.jpg"
                alt="Continuous Monitoring"
                className="rounded-lg shadow-lg w-full h-64 object-cover"
              />
            </div>
            <div className="order-1 md:order-2">
              <ProcessStep
                number={4}
                title="Continuous Monitoring"
                description="Our team continuously monitors your portfolio and makes adjustments as needed to respond to changing market conditions and your evolving financial goals."
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <ProcessStep
                number={5}
                title="Regular Reporting"
                description="We provide regular performance reports and updates, ensuring you have a clear understanding of your investment performance and progress toward your financial objectives."
              />
            </div>
            <div>
              <img
                src="/images/investment/assessment.jpg"
                alt="Regular Reporting"
                className="rounded-lg shadow-lg w-full h-64 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Investment Opportunities Section */}
      <ContentSection>
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Investment Opportunities</h2>
        <p className="text-lg text-gray-600 mb-8">
          Our global network and expertise allow us to identify and access a wide range of investment opportunities across different asset classes and markets:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <ImageWithOverlay
            imageSrc="/images/funds/equity.jpg"
            title="Equities"
            description="Access to global equity markets, including developed and emerging markets"
            className="h-64"
          />
          <ImageWithOverlay
            imageSrc="/images/structured/yield.jpg"
            title="Fixed Income"
            description="Government bonds, corporate bonds, and other fixed-income securities"
            className="h-64"
          />
          <ImageWithOverlay
            imageSrc="/images/funds/alternative.jpg"
            title="Alternative Investments"
            description="Private equity, hedge funds, real estate, and commodities"
            className="h-64"
          />
          <ImageWithOverlay
            imageSrc="/images/structured/protection.jpg"
            title="Structured Products"
            description="Custom-designed investment solutions with specific risk-return profiles"
            className="h-64"
          />
          <ImageWithOverlay
            imageSrc="/images/funds/multi-asset.jpg"
            title="Funds"
            description="Access to a diverse range of investment funds managed by leading asset managers"
            className="h-64"
          />
        </div>
        
        {/* Why Choose Us Section */}
        <h2 className="text-3xl font-serif font-bold text-gray-800 mb-8">Why Choose Our Investment Advisory Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
            </div>
            <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">Global Expertise</h3>
            <p className="text-gray-600">Our team has extensive experience in global markets</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
              </svg>
            </div>
            <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">Independent Advice</h3>
            <p className="text-gray-600">We provide objective and unbiased investment recommendations</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v1h8v-1zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-1a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v1h-3zM4.75 12.094A5.973 5.973 0 004 15v1H1v-1a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">Personalized Approach</h3>
            <p className="text-gray-600">Tailored strategies based on your unique financial situation</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
              </svg>
            </div>
            <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">Comprehensive Solutions</h3>
            <p className="text-gray-600">Access to a wide range of investment opportunities</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
              </svg>
            </div>
            <h3 className="text-xl font-serif font-bold text-gray-800 mb-2">Ongoing Support</h3>
            <p className="text-gray-600">Continuous monitoring and adjustment of your investment strategy</p>
          </div>
        </div>
      </ContentSection>
      
      <CallToAction />
    </Layout>
  );
};

export default InvestmentAdvisoryPage;
