import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContentSection from '../components/ContentSection';
import MarkdownContent from '../components/MarkdownContent';
import CallToAction from '../components/CallToAction';

interface ContentPageProps {
  title: string;
  description: string;
  fileName: string;
  showCallToAction?: boolean;
}

const ContentPage: React.FC<ContentPageProps> = ({
  title,
  description,
  fileName,
  showCallToAction = true,
}) => {
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      <PageHeader title={title} />
      <ContentSection>
        <MarkdownContent fileName={fileName} />
      </ContentSection>
      {showCallToAction && <CallToAction />}
    </Layout>
  );
};

export default ContentPage;
