import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContentSection from '../components/ContentSection';
import CallToAction from '../components/CallToAction';
import InsuranceCard from '../components/InsuranceCard';
import ProcessStep from '../components/ProcessStep';
import BenefitItem from '../components/BenefitItem';
import ImageWithOverlay from '../components/ImageWithOverlay';
import { pageContent } from '../utils/contentUtils';

const InsuranceSolutionsPage: React.FC = () => {
  const { title, description } = pageContent.insuranceSolutions;
  
  const insuranceProducts = [
    {
      title: 'Universal Life Insurance',
      description: 'Flexible, permanent life insurance coverage with an investment component.',
      features: [
        'Lifetime protection with adjustable death benefits',
        'Cash value accumulation with tax-advantaged growth',
        'Flexible premium payments',
        'Potential for policy loans and withdrawals',
        'Estate planning benefits'
      ],
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Private Placement Life Insurance (PPLI)',
      description: 'A sophisticated life insurance solution designed for high-net-worth individuals.',
      features: [
        'Significant tax advantages for investment growth',
        'Access to institutional investment strategies',
        'Customized investment options',
        'Privacy and asset protection benefits',
        'Efficient wealth transfer to future generations'
      ],
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Term Life Insurance',
      description: 'Cost-effective death benefit protection for a specified period.',
      features: [
        'Affordable coverage for temporary needs',
        'Fixed premiums for the term period',
        'High coverage amounts',
        'Convertibility options to permanent insurance',
        'Family protection during critical financial years'
      ],
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Endowment Plans, Savings Plans, and Annuities',
      description: 'Products designed to help you save systematically and achieve your financial goals.',
      features: [
        'Guaranteed returns and maturity benefits',
        'Regular income options',
        'Tax-efficient wealth accumulation',
        'Retirement planning solutions',
        'Education funding options'
      ],
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Healthcare Insurance',
      description: 'Comprehensive healthcare coverage to protect you and your family.',
      features: [
        'International health insurance plans',
        'Access to premium healthcare facilities worldwide',
        'Comprehensive coverage for medical expenses',
        'Preventive care benefits',
        'Emergency evacuation services'
      ],
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
        </svg>
      )
    }
  ];

  const processSteps = [
    {
      number: 1,
      title: 'Needs Assessment',
      description: 'We begin by understanding your specific protection needs, financial goals, and family situation to identify the most appropriate insurance solutions.'
    },
    {
      number: 2,
      title: 'Product Selection',
      description: 'Based on our assessment, we recommend insurance products that align with your needs, considering factors such as coverage requirements, budget, and long-term objectives.'
    },
    {
      number: 3,
      title: 'Implementation',
      description: 'We assist you throughout the application and underwriting process, ensuring a smooth and efficient implementation of your insurance solutions.'
    },
    {
      number: 4,
      title: 'Regular Review',
      description: 'We periodically review your insurance coverage to ensure it remains aligned with your changing needs and circumstances.'
    }
  ];

  const benefits = [
    {
      title: 'Wealth Protection',
      description: 'Safeguard your assets and provide financial security for your family.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Tax Efficiency',
      description: 'Optimize your tax situation through strategic insurance planning.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Estate Planning',
      description: 'Facilitate efficient wealth transfer to future generations.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
        </svg>
      )
    },
    {
      title: 'Diversification',
      description: 'Complement your investment portfolio with insurance-based solutions.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
      )
    },
    {
      title: 'Peace of Mind',
      description: 'Ensure financial protection against unforeseen events.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
        </svg>
      )
    }
  ];

  const whyChooseUs = [
    {
      title: 'Tailored Approach',
      description: 'Customized insurance solutions based on your unique needs.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Global Coverage',
      description: 'Access to international insurance products and providers.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Expert Guidance',
      description: 'Specialized knowledge of insurance solutions for high-net-worth individuals.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Integrated Planning',
      description: 'Insurance solutions that complement your overall financial strategy.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
      )
    },
    {
      title: 'Ongoing Support',
      description: 'Regular reviews and adjustments to ensure your coverage remains optimal.',
      icon: (
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
        </svg>
      )
    }
  ];
  
  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      
      {/* Hero Section */}
      <div className="relative text-white">
        <div className="absolute inset-0 z-0">
          <img
            src="/images/insurance/hero.jpg"
            alt="Insurance Solutions"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-primary bg-opacity-75"></div>
        </div>
        
        <div className="container py-24 relative z-10">
          <div className="max-w-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-6">
              {title}
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Comprehensive insurance solutions tailored for high-net-worth individuals and families
            </p>
          </div>
        </div>
      </div>
      
      {/* Introduction */}
      <ContentSection className="bg-white">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            At Mayfair Capital Markets, we offer a range of sophisticated insurance solutions designed to protect your wealth, 
            provide financial security for your family, and complement your overall financial strategy. 
            Our insurance solutions are tailored to meet the unique needs of high-net-worth individuals and families.
          </p>
        </div>
        
        {/* Insurance Products */}
        <div className="mb-20">
          <h2 className="text-3xl font-serif font-bold text-center mb-12">Our Insurance Products</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {insuranceProducts.map((product, index) => (
              <InsuranceCard
                key={index}
                title={product.title}
                description={product.description}
                features={product.features}
                icon={product.icon}
              />
            ))}
          </div>
        </div>
        
        {/* Our Approach */}
        <div className="mb-20">
          <h2 className="text-3xl font-serif font-bold text-center mb-12">Our Approach to Insurance Solutions</h2>
          <div className="bg-light-gray rounded-lg p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {processSteps.map((step, index) => (
                <ProcessStep
                  key={index}
                  number={step.number}
                  title={step.title}
                  description={step.description}
                />
              ))}
            </div>
          </div>
        </div>
        
        {/* Featured Insurance Types */}
        <div className="mb-20">
          <h2 className="text-3xl font-serif font-bold text-center mb-12">Featured Insurance Types</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ImageWithOverlay
              imageSrc="https://images.unsplash.com/photo-1560520031-3a4dc4e9de0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
              title="Life Insurance"
              description="Protect your family's financial future"
              className="h-64"
            />
            <ImageWithOverlay
              imageSrc="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
              title="Health Insurance"
              description="Premium healthcare coverage worldwide"
              className="h-64"
              overlayPosition="right"
            />
            <ImageWithOverlay
              imageSrc="https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
              title="Asset Protection"
              description="Safeguard your valuable assets"
              className="h-64"
              overlayPosition="right"
            />
            <ImageWithOverlay
              imageSrc="https://images.unsplash.com/photo-1434626881859-194d67b2b86f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
              title="Retirement Planning"
              description="Secure your financial future"
              className="h-64"
            />
          </div>
        </div>
        
        {/* Benefits */}
        <div className="mb-20">
          <h2 className="text-3xl font-serif font-bold text-center mb-12">Benefits of Our Insurance Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <BenefitItem
                key={index}
                title={benefit.title}
                description={benefit.description}
                icon={benefit.icon}
              />
            ))}
          </div>
        </div>
        
        {/* Why Choose Us */}
        <div>
          <h2 className="text-3xl font-serif font-bold text-center mb-12">Why Choose Our Insurance Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {whyChooseUs.map((item, index) => (
              <BenefitItem
                key={index}
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            ))}
          </div>
        </div>
      </ContentSection>
      
      <CallToAction />
    </Layout>
  );
};

export default InsuranceSolutionsPage;
