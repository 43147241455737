import React from 'react';

interface TimelineItemProps {
  year: string;
  title: string;
  description: string;
  isLeft?: boolean;
}

const TimelineItem: React.FC<TimelineItemProps> = ({ 
  year, 
  title, 
  description, 
  isLeft = true 
}) => {
  return (
    <div className={`flex w-full ${isLeft ? 'flex-row' : 'flex-row-reverse'}`}>
      <div className="w-5/12"></div>
      <div className="w-2/12 flex justify-center">
        <div className="relative flex h-full w-1 items-center justify-center">
          <div className="h-full w-1 bg-primary/30"></div>
          <div className="absolute flex h-10 w-10 items-center justify-center rounded-full border-4 border-primary bg-white text-sm font-semibold">
            {year}
          </div>
        </div>
      </div>
      <div className={`w-5/12 ${isLeft ? 'pl-4' : 'pr-4'}`}>
        <div className="rounded-lg bg-white p-6 shadow-md">
          <h3 className="mb-2 text-xl font-bold text-gray-900">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
