import React from 'react';

interface ImageWithOverlayProps {
  imageSrc: string;
  title: string;
  description?: string;
  className?: string;
  overlayPosition?: 'left' | 'right' | 'center';
}

const ImageWithOverlay: React.FC<ImageWithOverlayProps> = ({
  imageSrc,
  title,
  description,
  className = '',
  overlayPosition = 'left'
}) => {
  const positionClasses = {
    left: 'items-start text-left',
    right: 'items-end text-right',
    center: 'items-center text-center'
  };

  return (
    <div className={`relative overflow-hidden rounded-lg shadow-lg ${className}`}>
      <img 
        src={imageSrc} 
        alt={title} 
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex flex-col justify-end p-6">
        <div className={`flex flex-col ${positionClasses[overlayPosition]}`}>
          <h3 className="text-2xl font-serif font-bold text-white mb-2">{title}</h3>
          {description && <p className="text-white/90">{description}</p>}
        </div>
      </div>
    </div>
  );
};

export default ImageWithOverlay;
