import React from 'react';

const Partners: React.FC = () => {
  const partners = [
    'UBS',
    'HSBC',
    'Goldman Sachs',
    'Morgan Stanley',
    'Credit Agricole',
    'BNP Paribas',
    'Societe Generale',
    'Wells Fargo',
  ];

  return (
    <section className="py-16 bg-light-gray">
      <div className="container">
        <h2 className="text-3xl font-serif font-bold text-center mb-12">Our Partners</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex items-center justify-center bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <span className="text-xl font-bold text-gray-800">{partner}</span>
            </div>
          ))}
        </div>
        <p className="text-center mt-12 text-gray-600">
          We collaborate with leading financial institutions worldwide to provide you with the best
          investment opportunities and services.
        </p>
      </div>
    </section>
  );
};

export default Partners;
