import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction: React.FC = () => {
  return (
    <section className="py-16 bg-primary text-white">
      <div className="container">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-serif font-bold mb-6">
            Ready to Achieve Your Financial Goals?
          </h2>
          <p className="text-xl mb-8">
            Contact us today to learn how Mayfair Capital Markets can help you build and protect your wealth.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/contact-us" className="btn btn-secondary">
              Contact Us
            </Link>
            <Link to="/about" className="btn bg-white text-primary hover:bg-gray-100">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
