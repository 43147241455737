import React from 'react';

const Logo: React.FC = () => {
  return (
    <div className="flex items-center">
      <div className="bg-primary p-2 rounded">
        <span className="text-white font-serif font-bold text-xl">Mayfair</span>
      </div>
      <div className="ml-1">
        <span className="text-primary font-serif font-bold text-xl">Capital Markets</span>
      </div>
    </div>
  );
};

export default Logo;
