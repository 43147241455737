import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContentSection from '../components/ContentSection';
import ContactForm from '../components/ContactForm';
import { pageContent } from '../utils/contentUtils';

const ContactPage: React.FC = () => {
  const { title, description } = pageContent.contactUs;

  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      <PageHeader title={title} subtitle="Get in touch with our team of experts" />
      <ContentSection>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-serif font-bold mb-6">Contact Information</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-bold mb-2">Head Office</h3>
                <p className="text-gray-700">
                  Mayfair Capital Markets<br />
                  2 Leman Street<br />
                  London, E1W 9US<br />
                  United Kingdom
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">Contact Details</h3>
                <p className="text-gray-700">
                  <strong>Email:</strong> info@mayfair-capitalmarkets.com
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">Office Hours</h3>
                <p className="text-gray-700">
                  Monday to Friday: 9:00 AM - 5:00 PM (GMT)<br />
                  Saturday and Sunday: Closed
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-serif font-bold mb-6">Send Us a Message</h2>
            <ContactForm />
          </div>
        </div>
      </ContentSection>
    </Layout>
  );
};

export default ContactPage;
