import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Services from '../components/Services';
import WhyChooseUs from '../components/WhyChooseUs';
import Partners from '../components/Partners';
import CallToAction from '../components/CallToAction';
import { Helmet } from 'react-helmet';
import { pageContent } from '../utils/contentUtils';

const HomePage: React.FC = () => {
  const { title, description } = pageContent.home;

  return (
    <Layout>
      <Helmet>
        <title>{title} | Mayfair Capital Markets</title>
        <meta name="description" content={description} />
      </Helmet>
      <Hero />
      <Services />
      <WhyChooseUs />
      <Partners />
      <CallToAction />
    </Layout>
  );
};

export default HomePage;
