import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * Converts markdown content to React elements
 * @param markdown The markdown content to convert
 * @returns React elements representing the markdown content
 */
export const renderMarkdown = (markdown: string): ReactNode => {
  return React.createElement(ReactMarkdown, {}, markdown);
};
