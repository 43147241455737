import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary text-white">
      <div className="container py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="mb-4">
              <Logo />
            </div>
            <p className="text-gray-300">
              Mayfair Capital Markets is a premier financial services company providing comprehensive investment solutions to clients worldwide.
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/investment-advisory" className="text-gray-300 hover:text-white">
                  Investment Advisory
                </Link>
              </li>
              <li>
                <Link to="/wealth-management" className="text-gray-300 hover:text-white">
                  Wealth Management
                </Link>
              </li>
              <li>
                <Link to="/insurance-solutions" className="text-gray-300 hover:text-white">
                  Insurance Solutions
                </Link>
              </li>
              <li>
                <Link to="/structured-solutions" className="text-gray-300 hover:text-white">
                  Structured Solutions
                </Link>
              </li>
              <li>
                <Link to="/funds-solutions" className="text-gray-300 hover:text-white">
                  Funds Solutions
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-gray-300 hover:text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-gray-300 hover:text-white">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-gray-300 hover:text-white">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/cookies-policy" className="text-gray-300 hover:text-white">
                  Cookies Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-use" className="text-gray-300 hover:text-white">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-300">
          <p>&copy; {new Date().getFullYear()} Mayfair Capital Markets. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
