import React from 'react';
import ContentPage from './ContentPage';
import { pageContent } from '../utils/contentUtils';

const CookiesPolicyPage: React.FC = () => {
  const { title, description, fileName } = pageContent.cookiesPolicy;
  
  return (
    <ContentPage
      title={title}
      description={description}
      fileName={fileName}
      showCallToAction={false}
    />
  );
};

export default CookiesPolicyPage;
